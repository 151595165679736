import preparedReducer from './reducer';
import {
  updateFilterState,
  updateActivityLogFilter,
  updateIRFilter,
  updateMonitoringFilter,
  updateWorkHistoryFilter,
  updateTimesheetsFilter,
  reset,
  updateTab,
  updateActivityMapFilter,
  updateClientDashboardFilter,
  updateAdminClientFilter,
  updateAdminSiteFilter,
  updateAdminEmployeeFilter,
  updateAdminContactFilter,
  updateMobileUnitsFilter,
  setIsFilterSelected,
  updatePerformanceFilter,
  updateInfoBoardFilter,
  updateSort,
  updateFormResultsFilter,
  updateFormsFilter,
  updateTasksFilter,
  updateToursFilter,
  updateSystemLogFilter,
  updateVisitorsFilter,
  updateVisitorLogFilter,
  updateBIFilter,
  updateBIToursFilter,
} from './actions';

import initState from './states';

export { initState };

export const reducer = preparedReducer;

export const SLICE_NAME = 'pageStore';

export const selectors = (state) => state[SLICE_NAME];

export const selectActivityLogFilters = (state) => state[SLICE_NAME].activityLogFilters;
export const selectorsInfoBoard = (state) => state[SLICE_NAME].infoBoardFilters;
export const selectorsMonitoring = (state) => state[SLICE_NAME].monitoringFilters;
export const selectorsIR = (state) => state[SLICE_NAME].irFilters;
export const selectorsPerformance = (state) => state[SLICE_NAME].performanceFilters;
export const selectorsSystemLog = (state) => state[SLICE_NAME].systemLogFilters;
export const selectVisitorsFilters = (state) => state[SLICE_NAME].visitorsFilters;
export const selectVisitorLogFilters = (state) => state[SLICE_NAME].visitorLogFilters;
export const selectFormsFilter = (state) => state[SLICE_NAME].formsFilters;
export const selectFormResultsFilter = (state) => state[SLICE_NAME].formResultsFilters;
export const selectBIFilters = (state) => state[SLICE_NAME].biFilters
export const selectEventsBIFilters = (state) => state[SLICE_NAME].biFilters.events;
export const selectEntitiesBIFilters = (state) => state[SLICE_NAME].biFilters.entities;
export const selectBIToursFilters = (state) => state[SLICE_NAME].biToursFilters;
export const selectBIToursDateFilters = (state) => state[SLICE_NAME].biToursFilters.monthYear;
export const selectEmployeeFilters = (state) => state[SLICE_NAME].adminEmployeeFilters;

export const actions = {
  updateFilterState,
  updateActivityLogFilter,
  updateIRFilter,
  updateMonitoringFilter,
  updateWorkHistoryFilter,
  updateTimesheetsFilter,
  updateActivityMapFilter,
  updateClientDashboardFilter,
  reset,
  updateTab,
  updateAdminClientFilter,
  updateAdminSiteFilter,
  updateAdminEmployeeFilter,
  updateAdminContactFilter,
  updateMobileUnitsFilter,
  setIsFilterSelected,
  updatePerformanceFilter,
  updateInfoBoardFilter,
  updateSort,
  updateFormResultsFilter,
  updateFormsFilter,
  updateTasksFilter,
  updateToursFilter,
  updateSystemLogFilter,
  updateVisitorsFilter,
  updateVisitorLogFilter,
  updateBIFilter,
  updateBIToursFilter,
};
