import { all, put, call, takeLatest, select } from 'redux-saga/effects';

import {
  employeesRequest,
  employeesSuccess,
  employeesFailure,
  createEmployeeFailure,
  createEmployeeRequest,
  updateEmployeeRequest,
  updateEmployeeFailure,
  deleteEmployeeRequest,
  deleteEmployeeFailure,
  updateEmployeeFormRequest,
  updateEmployeeFormFailure,
  getUserFailure,
  getUserRequest,
  getUserSuccess,
  deleteEmployeeSuccess,
  updateEmployeeFormSuccess,
} from './actions';
import { updateLoginDetailsSaga, createLoginDetailsSaga } from '../users/sagas';
import axios from 'axios';
import { EMPLOYEES, EMPLOYEES_V2 } from 'app/api-routes';
import { uploadMediaAssets, mediaAssets } from 'app/api-routes';
import { MediaAssetTables, MediaAssetTypes } from 'ducks/mediaAssets/types';
import { NEW, TABLE_NAMES } from 'app/types/constants';
import { employeeInfoSaga } from 'ducks/employeeInfo/sagas';
import { isLoginDetailsChanged } from 'new-design/helpers';
import _ from 'lodash';
import { updateProfileFieldResultsSaga } from 'ducks/profiles/sagas';

function* employeesSaga({ payload: { siteID } = {} }) {
  try {
    const { payload, warnings } = yield axios.get(EMPLOYEES_V2, { params: { siteID: siteID || null } });

    yield put(employeesSuccess(payload, { warnings }));
  } catch (err) {
    yield put(employeesFailure(err));
  }
}

function* createEmployeeSaga({ payload: { employee, callback } }) {
  try {
    const { payload: id } = yield axios.post(EMPLOYEES, { payload: employee });
    yield call(employeesSaga, {});

    if (callback) {
      callback(id);
    }
  } catch (err) {
    yield put(createEmployeeFailure(err));
  }
}

function* updateEmployeeSaga({ payload: { employee, callback } }) {
  try {
    yield axios.put(`${EMPLOYEES}/${employee.employeeID}`, { payload: employee });

    if (callback) {
      callback();
    }
  } catch (err) {
    yield put(updateEmployeeFailure(err));
  }
}

function* deleteEmployeeSaga({ payload: { employeeID, callback, onFailure } }) {
  try {
    yield axios.delete(`${EMPLOYEES}/${employeeID}`);
    yield call(employeesSaga, {});

    if (callback) {
      callback();
    }
    yield put(deleteEmployeeSuccess(employeeID));
  } catch (err) {
    if (onFailure) {
      onFailure();
    }
    yield put(deleteEmployeeFailure(err));
  }
}

function* updateEmployeeFormSubmit({ payload: { id, values, callback } }) {
  try {
    let userID = values?.loginDetails?.userID;
    if (id !== NEW && values.loginDetails.loginName && values.loginDetails.roleID) {
      if (values?.loginDetails?.userID) {
        const loginDetails = yield select((state) => state.users.loginDetails[values.loginDetails.userID]);
        if (isLoginDetailsChanged({ prev: loginDetails, updated: values.loginDetails })) {
          yield call(updateLoginDetailsSaga, {
            payload: {
              userID: values.loginDetails.userID,
              payload: {
                ..._.omit(values.loginDetails, 'siteIDs'),
                userSites: {
                  userSite: values.loginDetails?.siteIDs?.map((siteID) => ({ siteID: siteID })),
                },
              },
            },
          });
        }
      } else {
        userID = yield call(createLoginDetailsSaga, {
          payload: {
            payload: {
              ..._.omit(values.loginDetails, 'siteIDs'),
              userSites: {
                userSite: values.loginDetails?.siteIDs?.map((siteID) => ({ siteID: siteID })),
              },
            },
          },
        });
      }
    }
    const { main, profile } = values;
    if (id !== NEW) {
      if (values.deleteImage !== null) {
        yield axios.delete(`${mediaAssets}/${values.deleteImage}`);
      }
      if (values.uploadedImage) {
        const formData = new FormData();
        formData.append('file', values.uploadedImage);

        const { payload: mediaAssetID } = yield axios.post(uploadMediaAssets, formData, {
          params: {
            entityID: id,
            type: MediaAssetTypes.PHOTO,
            tableName: MediaAssetTables.EMPLOYEES,
          },
        });
        main.photoID = mediaAssetID;
      }
    }

    if (id !== NEW && !_.isEmpty(profile)) {
      yield call(updateProfileFieldResultsSaga, {
        payload: { entityID: id, tableName: TABLE_NAMES.EMPLOYEES, values: profile },
      });
    }

    const body = {
      ...main,
      userID,
      ...(id === NEW ? {} : { employeeID: +id }),
      employeeNotes: {
        notes: Object.values(main.notes || {}),
      },
    };
    delete body.notes;

    if (id === NEW) {
      yield call(createEmployeeSaga, {
        payload: {
          employee: body,
          callback,
        },
      });
    } else {
      yield call(updateEmployeeSaga, {
        payload: {
          employee: body,
          callback,
        },
      });
    }
    // TODO: hot fix, update once logic will be updated. For refresh init state.
    yield call(employeeInfoSaga, { payload: id });
    yield put(updateEmployeeFormSuccess(id));
  } catch (err) {
    yield put(updateEmployeeFormFailure(err));
  }
}

export function* getUserSaga({ payload: { userID, callback } }) {
  try {
    const { payload } = yield axios.get(`${EMPLOYEES}/user/${userID}`);
    if (callback) {
      callback();
    }
    yield put(getUserSuccess(payload));
    return payload;
  } catch (err) {
    yield put(getUserFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(employeesRequest, employeesSaga),
    yield takeLatest(createEmployeeRequest, createEmployeeSaga),
    yield takeLatest(updateEmployeeRequest, updateEmployeeSaga),
    yield takeLatest(deleteEmployeeRequest, deleteEmployeeSaga),
    yield takeLatest(updateEmployeeFormRequest, updateEmployeeFormSubmit),
    yield takeLatest(getUserRequest, getUserSaga),
  ]);
}
