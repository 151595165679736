import { useDefaultOptions } from 'new-design/layouts/main/components/FilterChips/useDefaultOptions';

export const useURLStore = () => {
  const { isDefault, defaultValues } = useDefaultOptions();

  const addURLParam = (key, value) => {
    const _url = new URL(window.location.href);

    if (key === 'monthYear') {
      _url.searchParams.set(key, JSON.stringify(value));
    } else {
      _url.searchParams.set(key, value);
    }

    window.history.pushState({ path: _url.toString() }, '', _url.toString());
  };

  const deleteURLParam = (key) => {
    const _url = new URL(window.location.href);
    _url.searchParams.delete(key);
    window.history.pushState({ path: _url.toString() }, '', _url.toString());
  };

  const updateURL = (key, value) => {
    if (isDefault[key] && isDefault[key](value)) {
      deleteURLParam(key);
    } else {
      addURLParam(key, value);
    }
  };

  const prepareStoreFields = (filter) => {
    const searchParams = new URLSearchParams(window.location.search);
    for (let p of searchParams) {
      switch (p[0]) {
        case 'statuses':
          filter.statuses = p[1].split(',');
          break;
        case 'category':
          filter.category = p[1].split(',').map((el) => +el);
          break;
        case 'eventTypes': {
          const queryKeys = p[1].split(',');
          const eventTypes = defaultValues.eventTypes?.filter((keys) => queryKeys.includes(keys[0]));
          filter.eventTypes = eventTypes;
          break;
        }
        case 'eventType': {
          const queryKeys = p[1];
          filter.eventType = queryKeys;
          break;
        }
        case 'endDate':
        case 'startDate':
          filter[p[0]] = p[1];
          break;

        case 'sites':
        case 'infoCategory':
        case 'employees':
        case 'siteID':
        case 'entityID':
        case 'userID':
        case 'clientID':
        case 'supervisorID':
          filter[p[0]] = +p[1];
          break;

        case 'tableName':
        case 'viewType':
        case 'fieldType':  
          filter[p[0]] = p[1];
          break;

        case 'monthYear':
          filter[p[0]] = JSON.parse(p[1]);
          break;

        default:
          break;
      }
    }

    return filter;
  };

  return {
    prepareStoreFields,
    addURLParam,
    deleteURLParam,
    updateURL,
  };
};
