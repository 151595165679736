import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as mainLayout from 'ducks/main-layout';
import * as pageStore from 'ducks/page_store';
import { formatLocalDateTime } from 'util/formatting';
import { useOptions } from 'new-design/hooks/useOptions';
import _ from 'lodash';
import {
  getCitiesList,
  getProvinceList,
  getSitesOptions,
  getClientCitiesList,
  getClientsOptions,
  getClientStatesOptions,
  getSiteSupervisorsOptions,
} from 'app/selectors/genericSelector';
import { useIntl } from 'util/IntlMessages';
import { getCategories } from 'new-design/containers/operations/IncidentReports/selectors';
import { DATE_TIME_FORMAT_SHORT } from 'app/types/constants';

export const useChipsDescription = () => {
  const { employeesOptions, eventTypesOptions, eventTypesPerformanceOptions, infoNotesCategoryOptions } =
    useOptions();

  const { chipsStrip } = useSelector(mainLayout.selectors);
  const pageStoreItems = useSelector(pageStore.selectors);
  const sitesOptions = useSelector(getSitesOptions);
  const citiesOptions = useSelector(getCitiesList);
  const clientCitiesOptions = useSelector(getClientCitiesList);
  const provinceOptions = useSelector(getProvinceList);
  const categoryOptions = useSelector(getCategories);
  const clientsOptions = useSelector(getClientsOptions);
  const clientStateOptions = useSelector(getClientStatesOptions);
  const sitesSupervisorsOptions = useSelector(getSiteSupervisorsOptions);
  const { formatMessage } = useIntl();

  const chipsData = useMemo(() => {
    return pageStoreItems[chipsStrip] || {};
  }, [
    chipsStrip,
    pageStoreItems.activityLogFilters,
    pageStoreItems.irFilters,
    pageStoreItems.monitoringFilters,
    pageStoreItems.workHistoryFilters,
    pageStoreItems.timesheetsFilters,
    pageStoreItems.activityMapFilters,
    pageStoreItems.clientDashboardFilters,
    pageStoreItems.adminClientFilters,
    pageStoreItems.adminEmployeeFilters,
    pageStoreItems.adminMobileUnitsFilters,
    pageStoreItems.adminSiteFilters,
    pageStoreItems.adminToursFilters,
    pageStoreItems.performanceFilters,
    pageStoreItems.infoBoardFilters,
    pageStoreItems.formResultsFilters,
  ]);

  const startDateChip = useCallback(
    (key) => ({
      label: `${formatMessage({ id: 'start_date' })}: ${formatLocalDateTime(
        chipsData[key],
        DATE_TIME_FORMAT_SHORT
      )}`,
    }),
    [chipsData]
  );

  const endDateChip = useCallback(
    (key) => ({
      label: `${formatMessage({ id: 'end_date' })}: ${formatLocalDateTime(
        chipsData[key],
        DATE_TIME_FORMAT_SHORT
      )}`,
    }),
    [chipsData]
  );

  const categoryChip = useCallback(
    (key) => {
      const names = (categoryOptions || [])
        .filter((el) => _.flatMap(chipsData[key]).includes(el.key))
        .map((el) => el.label);

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'categories' })}: ${names.length > 1 ? names.length : names[0]}`,
      };
    },
    [chipsData, categoryOptions]
  );

  const employeesChip = useCallback(
    (key) => {
      const names = (employeesOptions || []).filter((el) => chipsData[key] === el.key).map((el) => el.label);

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'employee' })}: ${names[0]}`,
      };
    },
    [employeesOptions, chipsData]
  );

  const siteSupervisorChip = useCallback(
    (key) => {
      const names = (sitesSupervisorsOptions || []).filter((el) => chipsData[key] === el.key).map((el) => el.label);

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'account_manager' })}: ${names[0]}`,
      };
    },
    [sitesSupervisorsOptions, chipsData]
  );

  const siteChip = useCallback(
    (key) => {
      const names = (sitesOptions || []).filter((el) => chipsData[key] === el.key).map((el) => el.label);

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'site' })}: ${names[0]}`,
      };
    },
    [chipsData, sitesOptions]
  );

  const cityChip = useCallback(
    (key) => {
      const names = _.uniqBy([...citiesOptions, ...clientCitiesOptions], 'key')
        .filter((el) => chipsData[key] === el.key)
        .map((el) => el.label);

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'city' })}: ${names[0]}`,
      };
    },
    [citiesOptions, clientCitiesOptions, chipsData]
  );

  // Mobile patrol cities
  const mpCitiesChip = useCallback(
    (key) => {
      const names = chipsData[key];

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'cities' })}: ${names.length}`,
      };
    },
    [chipsData]
  );

  // Mobile patrol states
  const mpStatesChip = useCallback(
    (key) => {
      const names = chipsData[key];

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'states' })}: ${names.length}`,
      };
    },
    [chipsData]
  );

  const positionChip = useCallback(
    (key) => {
      const names = chipsData[key];

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'positions' })}: ${names.length}`,
      };
    },
    [chipsData]
  );

  const provinceChip = useCallback(
    (key) => {
      const names = (provinceOptions || []).filter((el) => chipsData[key] === el.key).map((el) => el.label);

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'province' })}: ${names[0]}`,
      };
    },
    [provinceOptions, chipsData]
  );

  const eventTypesChip = useCallback(
    (key) => {
      const keys = chipsData[key].map((el) => el.join(','));
      const names = (eventTypesOptions || [])
        .filter((el) => keys.includes(el.key.join(',')))
        .map((el) => el.label);

      return {
        isEmpty: false,
        label: `${formatMessage({ id: 'event_types' })}: ${names.length}`,
      };
    },
    [chipsData, eventTypesOptions]
  );

  const eventTypeChip = useCallback(
    (key) => {
      const keys = chipsData[key];
      const names = eventTypesPerformanceOptions?.find((el) => keys === el.key);

      return {
        isEmpty: !names,
        label: names?.label
          ? `${formatMessage({ id: 'event_type' })}: ${formatMessage({ id: names.label.props.id })}`
          : '',
      };
    },
    [chipsData, eventTypesOptions]
  );

  const clientChip = useCallback(
    (key) => {
      const names = (clientsOptions || []).filter((el) => chipsData[key] === el.key).map((el) => el.label);

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'client' })}: ${names[0]}`,
      };
    },
    [clientsOptions, chipsData]
  );

  const stateChip = useCallback(
    (key) => {
      const names = (clientStateOptions || [])
        .filter((el) => chipsData[key] === el.key)
        .map((el) => el.label);

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'state_province' })}: ${names[0]}`,
      };
    },
    [clientStateOptions, chipsData]
  );

  const infoCategoryChip = useCallback(
    (key) => {
      const names = (infoNotesCategoryOptions || [])
        .filter((el) => chipsData[key] === el.key)
        .map((el) => el.label);

      return {
        isEmpty: !names.length,
        label: `${formatMessage({ id: 'category' })}: ${names[0]}`,
      };
    },
    [infoNotesCategoryOptions, chipsData]
  );

  return {
    startDate: startDateChip,
    endDate: endDateChip,
    category: categoryChip,
    employees: employeesChip,
    userID: employeesChip,
    site: siteChip,
    sites: siteChip,
    siteID: siteChip,
    city: cityChip,
    mpCities: mpCitiesChip,
    mpStates: mpStatesChip,
    position: positionChip,
    province: provinceChip,
    eventTypes: eventTypesChip,
    eventType: eventTypeChip,
    clientID: clientChip,
    client: clientChip,
    state: stateChip,
    infoCategory: infoCategoryChip,
    supervisorID: siteSupervisorChip,
  };
};
