import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useOptions } from 'new-design/hooks/useOptions';
import moment from 'moment';
import _ from 'lodash';
import { getCategories } from 'new-design/containers/operations/IncidentReports/selectors';
import {
  getPositionsOptions,
} from 'app/selectors/genericSelector';
import { DATE_TIME_FORMAT_SHORT } from 'app/types/constants';

export const useDefaultOptions = () => {
  const {
    eventTypesOptions,
    mpCityOptions,
    mpStatesOptions,
  } = useOptions();
  const categoryOptions = useSelector(getCategories);
  const positionsOptions = useSelector(getPositionsOptions);

  const defaultValues = useMemo(() => {
    return {
      startDate: moment().startOf('month').format(DATE_TIME_FORMAT_SHORT),
      endDate: moment().endOf('day').format(DATE_TIME_FORMAT_SHORT),
      category: categoryOptions?.map((el) => el.key),
      eventTypes: eventTypesOptions.map((el) => el.key),
      eventType: 'scan',
      employees: '',
      sites: '',
      city: '',
      mpCities: _.uniq(_.map(mpCityOptions, 'key')),
      mpStates: _.uniq(_.map(mpStatesOptions, 'key')),
      province: '',
      position: _.map(positionsOptions, 'key'),
      infoCategory: '',
      userID: '',
    }
  }, [categoryOptions, eventTypesOptions, mpCityOptions, mpStatesOptions, positionsOptions]);

  const isDefaultStartDate = (value) => _.isEqual(defaultValues.startDate, value);
  const isDefaultEndDate = (value) => _.isEqual(defaultValues.endDate, value);
  const isDefaultEmployee = (value) => _.isEqual(defaultValues.employees, value);
  const isDefaultSite = (value) => _.isEqual(defaultValues.sites, value);
  const isDefaultCity = (value) => _.isEqual(defaultValues.city, value);
  const isDefaultMPCities = (value) => _.isEqual(defaultValues.mpCities, value);
  const isDefaultMPStates = (value) => _.isEqual(defaultValues.mpStates, value);
  const isDefaultProvince = (value) => _.isEqual(defaultValues.province, value);
  const isDefaultCategory = (value) => _.isEqual(_.flatMap(categoryOptions?.map((el) => el.key)).sort(), _.flatMap(value).sort());
  const isDefaultEventTypes = (value) => _.isEqual(_.flatMap(defaultValues.eventTypes).sort(), _.flatMap(value).sort());
  const isDefaultEventType = (value) => _.isEqual(defaultValues.eventType, value);
  const isDefaultPosition = (value) => _.isEqual(defaultValues.position, value);
  const isDefaultInfoCategory = (value) => _.isEqual(defaultValues.infoCategory, value);
  const isDefaultUserID = (value) => _.isEqual(defaultValues.userID, value);
  const isDefaultClientID = (value) => _.isEqual(defaultValues.clientID, value);
  const isDefaultFalse = () => false;

  return {
    defaultValues,
    isDefault: {
      startDate: isDefaultStartDate,
      endDate: isDefaultEndDate,
      employees: isDefaultEmployee,
      category: isDefaultCategory,
      sites: isDefaultSite,
      siteID: isDefaultSite,
      clientID: isDefaultClientID,
      city: isDefaultCity,
      province: isDefaultProvince,
      eventTypes: isDefaultEventTypes,
      eventType: isDefaultEventType,
      mpCities: isDefaultMPCities,
      mpStates: isDefaultMPStates,
      position: isDefaultPosition,
      infoCategory: isDefaultInfoCategory,
      userID: isDefaultUserID,
      monthYear: isDefaultFalse,
      viewType: isDefaultFalse,
      tableName: isDefaultFalse,
    }
  }
}
