import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    const paramsObject = {};

    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    return paramsObject;
  }, [search]);
};
