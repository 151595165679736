import { all, put, takeLatest } from 'redux-saga/effects';
import { formatDateTimeUTC } from 'util/formatting';

import { shiftLogFailure, shiftLogRequest, shiftLogSuccess } from './actions';

import axios from 'axios';
import { SHIFT_LOG } from 'app/api-routes';
import { SERVER_DATE_TIME_FORMAT } from 'app/types/constants';
import _ from 'lodash';

function* shiftLogSaga({
  payload: {
    startDate,
    endDate,
    clientID,
    siteID,
    shiftID,
    mobilePatrolID,
    userID,
    supervisorID,
    eventTypes,
    search,
    page,
    excel,
  },
}) {
  const params = {
    clientID: clientID || null,
    siteID: siteID || null,
    shiftID: shiftID || null,
    mobilePatrolID: mobilePatrolID || null,
    userID: userID || null,
    supervisorID: supervisorID || null,
    eventTypes: _.flatten(eventTypes) || null,
    search: search || null,
    page: page || null,
    fromDate: startDate ? formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT) : null,
    toDate: endDate ? formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT) : null,
  };

  try {
    const { payload } = yield axios.get(SHIFT_LOG, {
      params,
    });

    yield put(shiftLogSuccess({ page, excel, payload }));
  } catch (err) {
    yield put(shiftLogFailure(err));
  }
}

export default function* () {
  yield all([yield takeLatest(shiftLogRequest, shiftLogSaga)]);
}
