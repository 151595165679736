import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { FORMS } from 'app/api-routes';
import axios from 'axios';
import {
  approveFormResultFailure,
  approveFormResultRequest,
  approveFormResultSuccess,
  deleteFormResultFailure,
  deleteFormResultRequest,
  deleteFormResultSuccess,
  excelFormResultsFailure,
  excelFormResultsRequest,
  excelFormResultsSuccess,
  formResultInfoFailure,
  formResultInfoRequest,
  formResultInfoSuccess,
  formResultReportFailure,
  formResultReportRequest,
  formResultReportSuccess,
  formResultsFailure,
  formResultsRequest,
  formResultsSuccess,
  submitFormResultFailure,
  submitFormResultRequest,
  submitFormResultSuccess,
  updateFormResultFailure,
  updateFormResultRequest,
  updateFormResultSuccess,
} from './actions';
import { deserializeFields, prepareFieldResult } from 'ducks/forms/sagas';
import appConfig from 'config';
import { getCodes } from 'ducks/codes';
import CodeTypes from 'ducks/codes/types';
import {
  SPACE_LAYOUT,
  APPROVED_BY_INPUT,
  CATEGORY_INPUT,
  CLIENT_ADDRESS_INPUT,
  CLIENT_NAME_INPUT,
  COMMENT_LAYOUT,
  CREATED_BY_INPUT,
  DIVIDER_LAYOUT,
  FORM_ID_INPUT,
  LABEL_INPUT,
  PHOTOS_INPUT,
  SIGNATURE_INPUT,
  SITE_ADDRESS_INPUT,
  SITE_NAME_INPUT,
  DATE_INPUT,
  DATE_TIME_INPUT,
} from 'app/types/form-builder';
import _ from 'lodash';
import { formatAddressLine, formatDateTimeUTC, formatUTCInLocal } from 'util/formatting';
import { DATE_FORMAT, DATE_TIME_FORMAT_SHORT, SERVER_DATE_TIME_FORMAT } from 'app/types/constants';

const FIELD_TYPES_TO_EXCLUDE_IN_EXCEL = [
  SPACE_LAYOUT,
  DIVIDER_LAYOUT,
  FORM_ID_INPUT,
  CREATED_BY_INPUT,
  APPROVED_BY_INPUT,
  COMMENT_LAYOUT,
];

function* formResultsSaga({ payload: { formID, startDate, endDate, formFieldID, userID, supervisorID } }) {
  try {
    let fromDate, toDate;
    if (startDate != null && endDate != null) {
      fromDate = formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT);
      toDate = formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT);
    }

    const params = { fromDate, toDate };
    if (formID) {
      params.formID = formID;
    }

    if (formFieldID) {
      params.formFieldID = formFieldID;
    }

    if (userID) {
      params.userID = userID;
    }

    if (supervisorID) {
      params.supervisorID = supervisorID;
    }

    const { payload: formResults } = yield axios.get(`${FORMS}/results`, { params });

    yield put(formResultsSuccess(formResults));
  } catch (err) {
    yield put(formResultsFailure(err));
  }
}

function* formResultInfoSaga({ payload: formResultID }) {
  try {
    const { payload } = yield axios.get(`${FORMS}/results/${formResultID}`);

    const formResultInfo = {
      ...payload,
      creatorFields: deserializeFields(payload.creatorFields),
      reviewerFields: deserializeFields(payload.reviewerFields),
    };

    yield put(formResultInfoSuccess({ formResultID, payload: formResultInfo }));
  } catch (err) {
    yield put(formResultInfoFailure(err));
  }
}

function* submitFormResultSaga({ payload: formResultID }) {
  try {
    yield axios.post(`${FORMS}/results/${formResultID}/submit`);

    yield call(formResultInfoSaga, { payload: formResultID });
    yield put(submitFormResultSuccess(formResultID));
  } catch (err) {
    yield put(submitFormResultFailure(err));
  }
}

function* approveFormResultSaga({ payload: formResultID }) {
  try {
    yield axios.post(`${FORMS}/results/${formResultID}/approve`);

    yield call(formResultInfoSaga, { payload: formResultID });
    yield put(approveFormResultSuccess(formResultID));
  } catch (err) {
    yield put(approveFormResultFailure(err));
  }
}

function* updateFormResultSaga({
  payload: { formResultID, formID, clientID, siteID, fieldValues, userType },
}) {
  try {
    const fieldResults = [];

    const valuesEntries = Object.entries(fieldValues);
    for (let index = 0; index < valuesEntries.length; index++) {
      const [formFieldID, payload] = valuesEntries[index];
      const fieldResult = yield call(prepareFieldResult, { formID, formFieldID, payload });

      fieldResults.push(fieldResult);
    }

    const payload = {
      formID,
      clientID,
      siteID,
      shiftID: undefined,
      fieldResults,
    };

    yield axios.put(`${FORMS}/results/${formResultID}`, { payload }, { params: { userType } });

    yield call(formResultInfoSaga, { payload: formResultID });
    yield put(updateFormResultSuccess(formResultID));
  } catch (err) {
    yield put(updateFormResultFailure(err));
  }
}

function* deleteFormResultSaga({ payload: formResultID }) {
  try {
    yield axios.delete(`${FORMS}/results/${formResultID}`);

    yield put(deleteFormResultSuccess(formResultID));
  } catch (err) {
    yield put(deleteFormResultFailure(err));
  }
}

function* formResultReportSaga({ payload: formResultID }) {
  try {
    const { payload: mediaAssetID } = yield axios.get(`${FORMS}/results/${formResultID}/report`);

    yield put(
      formResultReportSuccess({
        formResultID,
        link: `${appConfig.serverURL}/api/mediaassets/stream/${mediaAssetID}`,
      })
    );
  } catch (err) {
    yield put(formResultReportFailure(err));
  }
}

function* excelFormResultsSaga({ payload: { formID, startDate, endDate, userID } }) {
  try {
    let fromDate, toDate;
    if (startDate != null && endDate != null) {
      fromDate = formatDateTimeUTC(startDate, SERVER_DATE_TIME_FORMAT);
      toDate = formatDateTimeUTC(endDate, SERVER_DATE_TIME_FORMAT);
    }

    const params = { fromDate, toDate };
    if (formID) {
      params.formID = formID;
    }

    if (userID) {
      params.userID = userID;
    }

    const { payload } = yield axios.get(`${FORMS}/results/excel`, { params });

    const excelData = yield call(prepareExcelData, { payload });

    yield put(excelFormResultsSuccess(excelData));
  } catch (err) {
    yield put(excelFormResultsFailure(err));
  }
}

function* prepareExcelData({ payload }) {
  const { list: sitesList } = yield select((state) => state.sites);
  const { list: clientsList } = yield select((state) => state.clients);
  const categories = yield select((state) => getCodes(state, CodeTypes.CATEGORIES));
  const labels = yield select((state) => getCodes(state, CodeTypes.LABELS));

  const { formResults, formFields } = payload;

  const schema = (formFields || [])
    .filter((field) => !FIELD_TYPES_TO_EXCLUDE_IN_EXCEL.includes(field.type))
    .map(({ formFieldID, config, type }) => ({
      title: JSON.parse(config).label,
      valueKey: ({ formFieldResults, siteID, siteName, clientID, clientName }) => {
        const fieldValue = formFieldResults[formFieldID]?.value;

        if (
          ![CLIENT_NAME_INPUT, CLIENT_ADDRESS_INPUT, SITE_NAME_INPUT, SITE_ADDRESS_INPUT].includes(type) &&
          !fieldValue
        ) {
          return '';
        }

        switch (type) {
          case CATEGORY_INPUT:
            return _.find(categories, ['id', Number(fieldValue)])?.description;

          case LABEL_INPUT: {
            const labelIDs = fieldValue.split(';').map((id) => Number(id));
            return labels
              .filter((label) => labelIDs.includes(label.id))
              .map(({ description }) => description)
              .join('; ');
          }

          case CLIENT_NAME_INPUT:
            return clientName;

          case CLIENT_ADDRESS_INPUT: {
            const client = _.find(clientsList, ['clientID', clientID]);

            if (!client) {
              return '';
            }

            return formatAddressLine(
              client.addressLine1,
              client.addressLine2,
              client.city,
              client.county,
              client.province,
              client.country,
              client.postalCode
            );
          }

          case SITE_NAME_INPUT:
            return siteName;

          case SITE_ADDRESS_INPUT: {
            const site = _.find(sitesList, ['siteID', siteID]);

            if (!site) {
              return '';
            }

            return formatAddressLine(
              site.addressLine1,
              site.addressLine2,
              site.city,
              site.county,
              site.province,
              site.country,
              site.postalCode
            );
          }

          case PHOTOS_INPUT:
            return _.isEmpty(fieldValue) ? 0 : fieldValue.split(';').length;

          case SIGNATURE_INPUT:
            return _.isEmpty(fieldValue) ? 0 : 1;

          case DATE_INPUT:
            return formatUTCInLocal(fieldValue, DATE_FORMAT);

          case DATE_TIME_INPUT:
            return formatUTCInLocal(fieldValue, DATE_TIME_FORMAT_SHORT);

          default:
            return fieldValue;
        }
      },
      width: { wpx: 200 },
    }));

  const data = (formResults || []).map((formResult) => ({
    ...formResult,
    formFieldResults: formResult.formFieldResults.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.formFieldID]: {
          value: curr.value || '',
        },
      }),
      {}
    ),
  }));

  return { data, schema };
}

export default function* () {
  yield all([
    yield takeLatest(formResultsRequest, formResultsSaga),
    yield takeLatest(formResultInfoRequest, formResultInfoSaga),
    yield takeLatest(submitFormResultRequest, submitFormResultSaga),
    yield takeLatest(approveFormResultRequest, approveFormResultSaga),
    yield takeLatest(updateFormResultRequest, updateFormResultSaga),
    yield takeLatest(deleteFormResultRequest, deleteFormResultSaga),
    yield takeLatest(formResultReportRequest, formResultReportSaga),
    yield takeLatest(excelFormResultsRequest, excelFormResultsSaga),
  ]);
}
