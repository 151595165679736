import { handleActions } from 'redux-actions';

import _ from 'lodash';
import {
  sitesSuccess,
  siteFormsSuccess,
  updateSiteFormsSuccess,
  visitorLogSettingSuccess,
  updateVisitorLogSettingSuccess,
  visitorLogSettingsSuccess,
  siteMobileSettingsSuccess,
  updateSiteMobileSettingsSuccess,
  sitesSupervisorsSuccess,
  updateSiteUsersSuccess,
  siteUsersSuccess,
} from './actions';

import initialState from './states';
import { logoutSuccess } from 'ducks/auth/actions';
import { updateFormEmailSettingsSuccess } from 'ducks/forms/actions';
import { TABLE_NAMES } from 'app/types/constants';

export default handleActions(
  {
    [sitesSuccess](state, { payload }) {
      if (_.isEqual(state, payload)) {
        return state;
      }

      return {
        ...state,
        list: [...payload],
      };
    },
    [siteFormsSuccess](state, { payload: { siteID, data } }) {
      return {
        ...state,
        forms: {
          ...state.forms,
          [siteID]: data,
        },
      };
    },
    [updateSiteFormsSuccess](state, { payload: { siteID, data } }) {
      return {
        ...state,
        forms: {
          ...state.forms,
          [siteID]: data,
        },
      };
    },
    [updateFormEmailSettingsSuccess](state, { payload: { formID, siteID, payload } }) {
      return {
        ...state,
        forms: {
          ...state.forms,
          [siteID]: state.forms[siteID].map((item) => {
            if (item.formID === formID) {
              return {
                ...item,
                employeeEmailSettingsCount: payload.filter((item) => item.tableName === TABLE_NAMES.EMPLOYEES)
                  .length,
                contactEmailSettingsCount: payload.filter((item) => item.tableName === TABLE_NAMES.CONTACTS)
                  .length,
              };
            } else {
              return item;
            }
          }),
        },
      };
    },
    [siteUsersSuccess](state, { payload: { data, siteID } }) {
      return {
        ...state,
        users: {
          ...state.users,
          [siteID]: data,
        },
      };
    },
    [updateSiteUsersSuccess](state, { payload: { siteID, data } }) {
      return {
        ...state,
        users: {
          ...state.users,
          [siteID]: data,
        },
      };
    },
    [visitorLogSettingSuccess](state, { payload: { siteID, setting } }) {
      return {
        ...state,
        visitorLogSettings: {
          ...state.visitorLogSettings,
          [siteID]: setting,
        },
      };
    },
    [updateVisitorLogSettingSuccess](state, { payload: { siteID, setting } }) {
      return {
        ...state,
        visitorLogSettings: {
          ...state.visitorLogSettings,
          [siteID]: setting,
        },
      };
    },
    [visitorLogSettingsSuccess](state, { payload: settings }) {
      const settingsMap = settings.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.siteID]: curr,
        }),
        {}
      );

      return {
        ...state,
        visitorLogSettings: {
          ...state.visitorLogSettings,
          ...settingsMap,
        },
      };
    },
    [siteMobileSettingsSuccess](state, { payload: { siteID, setting } }) {
      return {
        ...state,
        mobileSettings: {
          ...state.mobileSettings,
          [siteID]: setting,
        },
      };
    },
    [updateSiteMobileSettingsSuccess](state, { payload: { siteID, setting } }) {
      return {
        ...state,
        mobileSettings: {
          ...state.mobileSettings,
          [siteID]: {
            ...state.mobileSettings[siteID],
            ...setting,
          },
        },
      };
    },
    [sitesSupervisorsSuccess](state, { payload }) {
      return {
        ...state,
        supervisors: payload,
      };
    },
    [logoutSuccess]() {
      return initialState;
    },
  },
  initialState
);
