import React, { useCallback, useEffect, useMemo } from 'react';
import { Chip, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as mainLayout from 'ducks/main-layout';
import * as pageStore from 'ducks/page_store';
import { bindActionCreators } from 'redux';
import { useURLStore } from 'new-design/hooks/useURLStore';
import { useDefaultOptions } from './useDefaultOptions';
import { useChipsDescription } from './chipsDescription';
import { RemoveStatusIcon } from 'assets/icons';

const ALWAYS_VISIBLE_CHIPS = ['startDate', 'endDate'];

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      item: {
        marginRight: theme.spacing(1),
        borderColor: theme.palette.grey.color,
        '&:last-child': {
          marginRight: 0,
        },
      },
      deleteIcon: {
        '& path': {
          fill: theme.palette.grey.color,
        },
        '&:hover': {
          '& path': {
            fill: theme.palette.secondary.main,
          },
        },
      },
    }),
  {
    name: 'Chips',
  }
);

export const FilterChips = () => {
  const classes = useStyles();
  const { chipsStrip, hiddenChips } = useSelector(mainLayout.selectors);
  const pageStoreItems = useSelector(pageStore.selectors);
  const { defaultValues, isDefault } = useDefaultOptions();
  const { deleteURLParam } = useURLStore();
  const chipMapper = useChipsDescription();

  const {
    updateIRFilter,
    updateActivityLogFilter,
    updateInfoBoardFilter,
    updateFormResultsFilter,
    setIsFilterSelected,
    updateMonitoringFilter,
    updateWorkHistoryFilter,
    updateTimesheetsFilter,
    updateActivityMapFilter,
    updatePerformanceFilter,
    updateAdminClientFilter,
    updateAdminEmployeeFilter,
    updateMobileUnitsFilter,
    updateAdminSiteFilter,
    updateSystemLogFilter,
    updateVisitorLogFilter,
    updateToursFilter,
  } = bindActionCreators(pageStore.actions, useDispatch());

  const chipsData = useMemo(() => {
    return pageStoreItems[chipsStrip] || {};
  }, [
    chipsStrip,
    pageStoreItems.activityLogFilters,
    pageStoreItems.irFilters,
    pageStoreItems.monitoringFilters,
    pageStoreItems.workHistoryFilters,
    pageStoreItems.timesheetsFilters,
    pageStoreItems.activityMapFilters,
    pageStoreItems.clientDashboardFilters,
    pageStoreItems.adminClientFilters,
    pageStoreItems.adminEmployeeFilters,
    pageStoreItems.adminMobileUnitsFilters,
    pageStoreItems.adminSiteFilters,
    pageStoreItems.performanceFilters,
    pageStoreItems.infoBoardFilters,
    pageStoreItems.systemLogFilters,
    pageStoreItems.formResultsFilters,
    pageStoreItems.visitorLogFilters,
    pageStoreItems.adminToursFilters,
  ]);

  // Update function mapper
  const updateFilter = useMemo(
    () => ({
      irFilters: updateIRFilter,
      activityLogFilters: updateActivityLogFilter,
      infoBoardFilters: updateInfoBoardFilter,
      formResultsFilters: updateFormResultsFilter,
      workHistoryFilters: updateWorkHistoryFilter,
      timesheetsFilters: updateTimesheetsFilter,
      monitoringFilters: updateMonitoringFilter,
      activityMapFilters: updateActivityMapFilter,
      performanceFilters: updatePerformanceFilter,
      adminClientFilters: updateAdminClientFilter,
      adminEmployeeFilters: updateAdminEmployeeFilter,
      adminMobileUnitsFilters: updateMobileUnitsFilter,
      adminSiteFilters: updateAdminSiteFilter,
      systemLogFilters: updateSystemLogFilter,
      visitorLogFilters: updateVisitorLogFilter,
      adminToursFilters: updateToursFilter,
    }),
    []
  );

  const deleteChip = useCallback(
    (key) => {
      deleteURLParam(key);
      updateFilter[chipsStrip]({
        [key]: defaultValues[key],
      });
    },
    [defaultValues, chipsStrip]
  );

  const chips = useMemo(() => {
    return (Object.keys(chipsData) || [])
      .filter((key) => chipMapper[key] && !hiddenChips.includes(key))
      .map((key) => {
        return {
          key,
          isEmpty: false,
          isEqualToDefault: isDefault[key]?.(chipsData[key]),
          ...chipMapper[key](key),
        };
      })
      .filter((chip) => ALWAYS_VISIBLE_CHIPS.includes(chip.key) || (!chip.isEqualToDefault && !chip.isEmpty));
  }, [chipsData, chipMapper, isDefault]);

  useEffect(() => {
    const isChipDefault = chips.some(({ isEqualToDefault }) => !isEqualToDefault);
    setIsFilterSelected(isChipDefault);
  }, [chips]);

  if (!chipsStrip) {
    return null;
  }

  return (
    <>
      {chips?.map(({ label, key, isEqualToDefault }) => (
        <Chip
          key={`chip-item-${key}`}
          className={classes.item}
          label={label}
          variant="outlined"
          deleteIcon={<RemoveStatusIcon className={classes.deleteIcon} />}
          onDelete={
            !isEqualToDefault &&
            (() => {
              deleteChip(key);
            })
          }
        />
      ))}
    </>
  );
};
