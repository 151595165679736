import React, { useCallback, useState } from 'react';
import * as users from 'ducks/users';
import { useDispatch } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { EyeOffIcon, EyeShowIcon, PasswordIcon } from 'assets/icons';
import IntlMessages from 'util/IntlMessages';
import Modal from 'new-design/components/Modal';
import { FormTextField, Form } from 'new-design/common/ui/form-inputs';
import { Button, IconButton } from 'new-design/common/ui';
import * as Yup from 'yup';
import { bindActionCreators } from 'redux';
import { useIntl } from 'util/IntlMessages';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      input: {
        '&:not(:nth-child(1))': {
          marginTop: theme.spacing(3),
        },
      },
      modalTitle: {
        fontWeight: 600,
        textAlign: 'center',
        fontSize: 28,
        [theme.breakpoints.down('lg')]: {
          fontSize: 24,
        },
      },
      actions: {
        display: 'flex',
        marginTop: 48,
      },
      actionLeft: {
        width: '50%',
        marginRight: 8,
      },
      actionRight: {
        width: '50%',
        marginLeft: 8,
      },
      eyeIcon: {
        '& path': {
          fill: theme.palette.secondary.main,
        },
      },
    }),
  {
    name: 'ChangePasswordModal',
  }
);

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(<IntlMessages id="form_required" />),
  newPassword: Yup.string().required(<IntlMessages id="form_required" />),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required(<IntlMessages id="form_required" />),
});

const ChangePasswordModal = ({
  userID,
  modalOpened,
  changePasswordClose,
  oldPasswordLabel,
  newPasswordLabel,
  confirmPasswordLabel,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { requestChangePassword } = bindActionCreators(users.actions, useDispatch());
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [adminPasswordVisible, setAdminPasswordVisible] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      requestChangePassword({
        userID,
        field: {
          password: values.oldPassword,
          newPassword: values.newPassword,
        },
        callback: changePasswordClose,
      });
    },
    [userID]
  );

  return (
    <>
      <Modal open={modalOpened}>
        <Box>
          <Box marginBottom={4}>
            <Typography variant="h4" className={classes.modalTitle}>
              {formatMessage({ id: 'change_password' })}
            </Typography>
          </Box>
          <Form
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            }}
            withSubmitProps
            validationSchema={changePasswordSchema}
            onSubmit={onSubmit}
          >
            {(submitForm) => (
              <>
                <FormTextField
                  fullWidth
                  type={adminPasswordVisible ? 'text' : 'password'}
                  name="oldPassword"
                  label={oldPasswordLabel || formatMessage({ id: 'old_password' })}
                  className={classes.input}
                  icon={<PasswordIcon />}
                  endIcon={
                    <IconButton onClick={() => setAdminPasswordVisible((prev) => !prev)}>
                      {adminPasswordVisible ? (
                        <EyeOffIcon className={classes.eyeIcon} />
                      ) : (
                        <EyeShowIcon className={classes.eyeIcon} />
                      )}
                    </IconButton>
                  }
                />
                <FormTextField
                  fullWidth
                  name="newPassword"
                  type={passwordVisible ? 'text' : 'password'}
                  label={newPasswordLabel || formatMessage({ id: 'new_password' })}
                  className={classes.input}
                  icon={<PasswordIcon />}
                  endIcon={
                    <IconButton onClick={() => setPasswordVisible((prev) => !prev)}>
                      {passwordVisible ? (
                        <EyeOffIcon className={classes.eyeIcon} />
                      ) : (
                        <EyeShowIcon className={classes.eyeIcon} />
                      )}
                    </IconButton>
                  }
                />
                <FormTextField
                  fullWidth
                  name="confirmPassword"
                  type={passwordVisible ? 'text' : 'password'}
                  label={confirmPasswordLabel || formatMessage({ id: 'confirm_password' })}
                  className={classes.input}
                  icon={<PasswordIcon />}
                  endIcon={
                    <IconButton onClick={() => setPasswordVisible((prev) => !prev)}>
                      {passwordVisible ? (
                        <EyeOffIcon className={classes.eyeIcon} />
                      ) : (
                        <EyeShowIcon className={classes.eyeIcon} />
                      )}
                    </IconButton>
                  }
                />
                <Box className={classes.actions}>
                  <Box className={classes.actionLeft}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={changePasswordClose}
                      className={classes.actionButton}
                    >
                      {formatMessage({ id: 'cancel' })}
                    </Button>
                  </Box>
                  <Box className={classes.actionRight}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.actionButton}
                      onClick={() => {
                        submitForm();
                        changePasswordClose();
                      }}
                    >
                      {formatMessage({ id: 'confirm' })}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Form>
        </Box>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
