import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { QRIcon, NFCIcon, StandardTourIcon, RandomTourIcon } from 'assets/icons';
import { RoleCodes } from 'ducks/roles/types';

export const contactClientTitle = [
  {
    key: '78',
    label: 'Mr.',
  },
  {
    key: '79',
    label: 'Mrs.',
  },
  {
    key: '80',
    label: 'Ms.',
  },
];

export const employeeOnDutyStates = [
  {
    label: <IntlMessages id="on_duty" />,
    key: true,
  },
  {
    label: <IntlMessages id="off_duty" />,
    key: false,
  },
  {
    label: <IntlMessages id="not_applicable_abbreviation" />,
    key: null,
  },
];

export const statuses = (params) => {
  let items = [
    {
      key: 'A',
      label: <IntlMessages id="active" />,
    },
    {
      key: 'S',
      label: <IntlMessages id="suspended" />,
    },
    {
      key: 'D',
      label: <IntlMessages id="deleted" />,
    },
  ];

  if (params?.withNew) {
    items.unshift({
      label: <IntlMessages id="new" />,
      key: 'N',
    });
  }

  if (params?.withAll) {
    items = [
      {
        label: <IntlMessages id="all" />,
        key: 'all',
      },
      ...items,
    ];
  }

  return items;
};

export const radioItems = [
  {
    value: 'qr_code',
    label: <IntlMessages id="qr_tags" />,
    icon: QRIcon,
  },
  {
    value: 'nfc',
    label: <IntlMessages id="nfc_tags" />,
    icon: NFCIcon,
  },
  {
    value: 'none',
    label: <IntlMessages id="no_tags" />,
  },
];

export const tourTypeItems = [
  {
    value: 'static',
    label: <IntlMessages id="static" />,
    icon: StandardTourIcon,
  },
  {
    value: 'dynamic',
    label: <IntlMessages id="dynamic" />,
    icon: RandomTourIcon,
  },
];

export const roleOptions = [
  {
    key: RoleCodes.ADMIN,
    label: <IntlMessages id="role_admin" />,
  },
  {
    key: RoleCodes.MANAGER,
    label: <IntlMessages id="role_manager" />,
  },
  {
    key: RoleCodes.DISPATCH,
    label: <IntlMessages id="role_dispatch" />,
  },
  {
    key: RoleCodes.SUPERVISOR,
    label: <IntlMessages id="role_spv" />,
  },
  {
    key: RoleCodes.GUARD,
    label: <IntlMessages id="role_guard" />,
  },
];
