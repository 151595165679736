import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  sitesFailure,
  sitesRequest,
  sitesSuccess,
  addSiteFailure,
  addSiteRequest,
  updateSiteFailure,
  updateSiteRequest,
  updateSiteSuccess,
  updateSiteFormsRequest,
  updateSiteFormsSuccess,
  updateSiteFormsFailure,
  siteFormsRequest,
  siteFormsSuccess,
  siteFormsFailure,
  visitorLogSettingRequest,
  visitorLogSettingSuccess,
  visitorLogSettingFailure,
  updateVisitorLogSettingRequest,
  updateVisitorLogSettingSuccess,
  updateVisitorLogSettingFailure,
  visitorLogSettingsRequest,
  visitorLogSettingsSuccess,
  visitorLogSettingsFailure,
  siteMobileSettingsRequest,
  siteMobileSettingsSuccess,
  siteMobileSettingsFailure,
  updateSiteMobileSettingsRequest,
  updateSiteMobileSettingsSuccess,
  updateSiteMobileSettingsFailure,
  sitesSupervisorsSuccess,
  sitesSupervisorsFailure,
  sitesSupervisorsRequest,
  updateSiteUsersSuccess,
  updateSiteUsersFailure,
  siteUsersRequest,
  updateSiteUsersRequest,
  siteUsersSuccess,
  siteUsersFailure,
} from './actions';
import axios from 'axios';
import { SITES, SITES_V2 } from 'app/api-routes';
import { siteInfoSaga } from 'ducks/siteInfo/sagas';

function* sitesSaga() {
  try {
    const { payload, warnings } = yield axios.get(SITES);
    yield put(sitesSuccess(payload, { warnings }));
  } catch (err) {
    yield put(sitesFailure(err));
  }
}

function* addSitesSaga({ payload: { data, onSuccess, onFailure } }) {
  try {
    const { payload } = yield axios.post(SITES, { payload: data });
    yield call(sitesSaga);
    onSuccess?.(payload);
  } catch (err) {
    onFailure?.();
    yield put(addSiteFailure(err));
  }
}

function* updateSitesSaga({ payload: { data, siteID, onSuccess } }) {
  try {
    yield axios.put(`${SITES}/${siteID}`, { payload: data });
    onSuccess?.();
    yield put(updateSiteSuccess({ siteID, data }));
    yield call(siteInfoSaga, { payload: siteID });
  } catch (err) {
    yield put(updateSiteFailure(err));
  }
}

function* updateSiteFormsSaga({ payload: { data, siteID, onSuccess } }) {
  try {
    yield axios.put(`${SITES}/${siteID}/forms`, { payload: data.map((el) => el.formID) });
    yield put(updateSiteFormsSuccess({ siteID, data }));
    onSuccess?.();
  } catch (err) {
    yield put(updateSiteFormsFailure(err));
  }
}

function* siteFormsSaga({ payload: siteID }) {
  try {
    const { payload } = yield axios.get(`${SITES}/${siteID}/forms`);
    yield put(siteFormsSuccess({ siteID, data: payload }));
  } catch (err) {
    yield put(siteFormsFailure(err));
  }
}

function* visitorLogSettingSaga({ payload: siteID }) {
  try {
    const { payload } = yield axios.get(`${SITES}/${siteID}/visitor-log-setting`);
    yield put(visitorLogSettingSuccess({ siteID, setting: payload }));
  } catch (err) {
    yield put(visitorLogSettingFailure(err));
  }
}

function* visitorLogSettingsSaga({ payload: { siteIDs, userID } = {} }) {
  try {
    const { payload } = yield axios.get(`${SITES}/visitor-log-settings`, {
      params: {
        siteIDs,
        userID,
      },
    });
    yield put(visitorLogSettingsSuccess(payload));
  } catch (err) {
    yield put(visitorLogSettingsFailure(err));
  }
}

function* updateVisitorLogSettingSaga({ payload: { siteID, setting } }) {
  try {
    yield axios.put(`${SITES}/${siteID}/visitor-log-setting`, { payload: setting });
    yield put(updateVisitorLogSettingSuccess({ siteID, setting }));
  } catch (err) {
    yield put(updateVisitorLogSettingFailure(err));
  }
}

function* siteMobileSettingsSaga({ payload: siteID }) {
  try {
    const { payload } = yield axios.get(`${SITES}/${siteID}/mobile-settings`);
    yield put(siteMobileSettingsSuccess({ siteID, setting: payload }));
  } catch (err) {
    yield put(siteMobileSettingsFailure(err));
  }
}

function* updateSiteMobileSettingsSaga({ payload: { siteID, setting } }) {
  try {
    yield axios.put(`${SITES}/${siteID}/mobile-settings`, { payload: setting });
    yield put(updateSiteMobileSettingsSuccess({ siteID, setting }));
  } catch (err) {
    yield put(updateSiteMobileSettingsFailure(err));
  }
}

function* sitesSupervisorsSaga() {
  try {
    const { payload } = yield axios.get(`${SITES_V2}/supervisors`);
    yield put(sitesSupervisorsSuccess(payload));
  } catch (err) {
    yield put(sitesSupervisorsFailure(err));
  }
}
function* siteUsersSaga({ payload: siteID }) {
  try {
    const { payload } = yield axios.get(`${SITES_V2}/${siteID}/users`);
    yield put(siteUsersSuccess({ siteID, data: payload }));
  } catch (err) {
    yield put(siteUsersFailure(err));
  }
}

function* updateSiteUsersSaga({ payload: { data, siteID } }) {
  try {
    yield axios.put(`${SITES_V2}/${siteID}/users`, { payload: data });
    yield put(updateSiteUsersSuccess({ siteID, data }));
  } catch (err) {
    yield put(updateSiteUsersFailure(err));
  }
}

export default function* () {
  yield all([
    yield takeLatest(sitesRequest, sitesSaga),
    yield takeLatest(addSiteRequest, addSitesSaga),
    yield takeLatest(updateSiteRequest, updateSitesSaga),
    yield takeLatest(updateSiteFormsRequest, updateSiteFormsSaga),
    yield takeLatest(siteFormsRequest, siteFormsSaga),
    yield takeLatest(siteUsersRequest, siteUsersSaga),
    yield takeLatest(updateSiteUsersRequest, updateSiteUsersSaga),
    yield takeLatest(visitorLogSettingRequest, visitorLogSettingSaga),
    yield takeLatest(updateVisitorLogSettingRequest, updateVisitorLogSettingSaga),
    yield takeLatest(visitorLogSettingsRequest, visitorLogSettingsSaga),
    yield takeLatest(siteMobileSettingsRequest, siteMobileSettingsSaga),
    yield takeLatest(updateSiteMobileSettingsRequest, updateSiteMobileSettingsSaga),
    yield takeLatest(sitesSupervisorsRequest, sitesSupervisorsSaga),
  ]);
}
