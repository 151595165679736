import React from 'react';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      horizontalDivider: {
        width: '100%',
        height: 1,
        background: ({ color }) => color || '#EEEEEE',
        margin: (props) => `${props.margin}px 0`,
        [theme.breakpoints.down('lg')]: {
          margin: (props) => `${props.margin / 2}px 0`,
        },
      },
    }),
  {
    name: 'HDivider',
  }
);

const HDivider = ({ margin = 16, color }) => {
  const classes = useStyles({ margin, color });

  return <div className={classes.horizontalDivider} />;
};

export { HDivider };
