import { Typography, Box, createStyles, Tooltip } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { matchPath } from 'react-router-dom';
import _ from 'lodash';
import * as auth from 'ducks/auth';
import { useTopMenu, topClientMenu } from '../Header/menus';
import { CLIENT_TYPE } from 'app/types/constants';
import { usePageId } from 'new-design/hooks/usePageId';
import { useStyles as useMainStyles } from '../../styles';
import { selectBreadcrumbsState } from './selectors';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      item: {
        fontSize: 16,
        lineHeight: '24px',
        [theme.breakpoints.down('lg')]: {
          fontSize: 14,
          lineHeight: '20px',
        },
      },
      activeItem: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '24px',
        marginLeft: 2,
        [theme.breakpoints.down('lg')]: {
          fontSize: 18,
          lineHeight: '20px',
        },
      },
      pointer: {
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      idLabel: {
        marginLeft: 4,
        fontSize: 16,
        color: '#000',
        fontWeight: 600,
      },
    }),
  {
    name: 'Breadcrumbs',
  }
);

const getElements = (menu, els, isFound, searchedLink) => {
  if (isFound) {
    return [els, isFound];
  }

  const response = [];
  menu.forEach((menuItem) => {
    if (menuItem?.disabled) {
      return [];
    }
    const match = matchPath(searchedLink, {
      path: menuItem.link?.split('#')[0],
      exact: true,
      strict: false,
    });

    const params = matchPath(searchedLink, menuItem.link)?.params;

    if (menuItem.children?.length || menuItem.subpages?.length) {
      response.push(
        getElements(
          menuItem.children || menuItem.subpages,
          [
            ...els,
            {
              name: menuItem.name,
              link: menuItem.link,
              historyBack: menuItem.historyBack,
              params,
            },
          ],
          match,
          searchedLink
        )
      );
    } else {
      response.push([
        [
          ...els,
          {
            name: menuItem.name,
            historyBack: menuItem.historyBack,
            link: menuItem.link.replace('null', ''),
            params,
          },
        ],
        match,
      ]);
    }
  });

  return response?.find((el) => !!el && !!el[1]);
};

const Breadcrumbs = ({ setIsBigger, parentRef, limited, maxWidth }) => {
  const classes = useStyles();
  const history = useHistory();
  const topMenu = useTopMenu();
  const ref = useRef(null);
  const [elements, setElements] = useState([]);
  const { hashId: id } = usePageId();
  const { userInfo } = useSelector(auth.selectors);
  const state = useSelector(selectBreadcrumbsState);

  const menu = userInfo.userType === CLIENT_TYPE ? topClientMenu : topMenu;

  const checkElements = () => {
    const [items] = getElements(menu, [], false, history.location.pathname) ?? [];
    setElements(items ?? []);
  };

  const addListeners = () => {
    history.listen((location) => {
      const [_items] = getElements(menu, [], false, location.pathname) ?? [];
      setElements(_items ?? []);
    });
  };

  useEffect(() => {
    checkElements();
    addListeners();
  }, [history.location]);

  useEffect(() => {
    if (!limited) return;
    setIsBigger(parentRef.current?.offsetWidth < parentRef.current?.children[0]?.offsetWidth);
  }, [ref.current?.offsetWidth, limited]);

  const onPageNameClick = useCallback((index, historyBack, link) => {
    if (index > 0) {
      if (historyBack) {
        history.goBack();
      } else {
        history.push(link);
      }
    }
  }, []);

  return (
    <Box display="flex" alignItems="center" ref={ref} width={maxWidth ? maxWidth - 16 : undefined}>
      {elements.map(({ name, link, historyBack, params }, index) => {
        const breadcrumbTitle = _.isFunction(name) ? name({ params: params || {}, state }) : name;

        return elements.length - 1 !== index ? (
          <React.Fragment key={`${link}-${name?.props?.id}`}>
            <Typography
              color="secondary"
              className={clsx(classes.item, {
                [classes.pointer]: index > 0,
              })}
              onClick={() => onPageNameClick(index, historyBack, link)}
            >
              {breadcrumbTitle}
            </Typography>
            <Typography color="secondary" className={classes.item}>
              {' '}
              /{' '}
            </Typography>
          </React.Fragment>
        ) : (
          <Tooltip title={breadcrumbTitle} key={`${link}-${name?.props?.id}`}>
            <Typography noWrap color="textPrimary" className={classes.activeItem}>
              {breadcrumbTitle}
            </Typography>
          </Tooltip>
        );
      })}
      {!!id && <Typography className={classes.idLabel}>{`(#${id})`}</Typography>}
    </Box>
  );
};

export const LimitedBreadCrumbs = ({ maxWidth }) => {
  const classes = useMainStyles();
  const [isChildBigger, setIsChildBigger] = useState(false);
  const breadCrumbsContainerRef = useRef();
  const history = useHistory();

  useEffect(() => {
    setIsChildBigger(false);
  }, [history.location.pathname]);

  return (
    <>
      <div
        className={clsx({
          [classes.breadcrumbsLimitedContainer]: false,
          [classes.breadcrumbsContainer]: true,
        })}
        ref={breadCrumbsContainerRef}
      >
        <Breadcrumbs
          parentRef={breadCrumbsContainerRef}
          setIsBigger={setIsChildBigger}
          limited={false}
          maxWidth={maxWidth}
        />
      </div>
      {isChildBigger && (
        <Box marginLeft={0.5}>
          <Typography style={{ fontSize: 20, color: 'black' }}>...</Typography>
        </Box>
      )}
    </>
  );
};

export default Breadcrumbs;
