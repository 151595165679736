import { createSelector } from 'reselect';
import * as sites from 'ducks/sites';
import * as employees from 'ducks/employees';
import * as clients from 'ducks/clients';
import * as mobilePatrols from 'ducks/mobile-patrols';
import * as contacts from 'ducks/contacts';
import * as forms from 'ducks/forms';
import * as tasks from 'ducks/tasks';
import * as tours from 'ducks/tours';
import * as auditLog from 'ducks/auditLog';
import * as visitors from 'ducks/visitors';
import * as visitorLog from 'ducks/visitorLog';
import * as siteInfo from 'ducks/siteInfo';

export const selectBreadcrumbsState = createSelector(
  sites.selectors,
  employees.selectors,
  clients.selectors,
  mobilePatrols.selectors,
  contacts.selectors,
  forms.selectors,
  tasks.selectors,
  tours.selectors,
  auditLog.selectors,
  visitors.selectors,
  visitorLog.selectors,
  siteInfo.selectors,
  (
    { list: sitesList },
    { list: employeesList },
    { list: clientsList },
    { list: mobilePatrolsList },
    { info: contactInfo },
    { info: formInfo },
    { info: taskInfo },
    { info: tourInfo },
    { info: auditLogInfo },
    { info: visitorsInfo },
    { info: visitorLogInfo },
    { siteInformation }
  ) => ({
    sitesList,
    employeesList,
    clientsList,
    mobilePatrolsList,
    contactInfo,
    formInfo,
    taskInfo,
    tourInfo,
    auditLogInfo,
    visitorsInfo,
    visitorLogInfo,
    siteInformation,
  })
);
